<template>
  <div class="about">
    <sub-banner :title="banner.title" :subTitle="banner.subTitle" :bannerBg="bannerImg"></sub-banner>
    <section class="compute">
      <common-title title="趋动AI计算" subTitle="VIRTAI TECH AI computing"></common-title>
      <p class="text" v-html="$options.filters.changeLine(contentAbout.companyIntro.content)"></p>
      <div class="img-box">
        <img src="@/assets/images/about/bg_compute.jpeg" alt="公司介绍">
      </div>
    </section>
    <section class="team">
      <common-title title="团队简介" subTitle="Team introduction"></common-title>
      <ul class="list">
        <li class="team-item" v-for="item of contentAbout.teamMembers" :key="item.contentItemId">
          <div class="intro">
            <h4>{{item.name}}</h4>
            <h5 :class="{small: item.position.length>8}">{{item.position}}</h5>
          </div>
          <div class="info-box">
            <div class="title-box">
              <strong v-for="(t,index) of item.titles" :key="index" :title="t">{{t}}</strong>
            </div>
            <p class="description" v-html="item.description"></p>
          </div>
        </li>
      </ul>
    </section>
    <!-- <section class="develop">
      <common-title title="发展历程" subTitle="Team THE DEVELOPMENT COURSE" light="yes"></common-title>
      <div class="timeline-box">
        时间线
        <div class="timeline">
          <ol class="year">
            <li v-for="year of years" :key="year">{{year}}</li>
          </ol>
          <ol class="event">
            <li v-for="(event,index) of timeLineData"
                :key="event.contentItemId"
                :title="event.keyWords"
                @click="changeSlide(index)"
                :class="{active: activeIndex === index}"
                :style="`left: ${event.left ? event.left : 0}%`">
              <div v-if="event.left">
                <i></i>
                <p>{{event.keyWords}}</p>
              </div>
            </li>
          </ol>
        </div>
      </div>
      <div class="swiper-box">
        <van-swipe class="my-swipe" :show-indicators="false" ref="swipe-dev" @change="handleSwipeChange">
          <van-swipe-item v-for="(item, index) of timeLineData" :key="index">
            <div class="event-img img-box">
              <img class="event-img" :src="item.picture" :alt="item.keyWords">
            </div>
            <div class="text-box">
              <h4>{{Number(item.month) + '月  ' + item.title}}</h4>
              <p>{{item.content}}</p>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="swipe-btn-box">
        <div class="swipe-button" @click="changeSwipe('prev')">
          <svg viewBox="0 0 1024 1024">
            <path :d="svgPath.arrowBorder" p-id="1244"></path><path :d="svgPath.arrowLeft" p-id="1245"></path>
          </svg>
        </div>
        <div class="swipe-button" @click="changeSwipe('next')">
          <svg viewBox="0 0 1024 1024">
            <path :d="svgPath.arrowBorder" p-id="1063"></path><path :d="svgPath.arrowRight" p-id="1064"></path>
          </svg>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
import pageMixin from '@/mixins/pageMixin'
import commonTitle from '@/components/commonTitle'
import subBanner from '@/components/subBanner'
import bannerImg from '@/assets/images/about/about-company-banner.jpeg'

import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'about',
  mixins: [pageMixin],
  components: {
    commonTitle,
    subBanner
  },
  data () {
    return {
      banner: {
        title: '关于公司',
        subTitle: 'About the company'
      },
      activeIndex: 0,
      years: [],
      bannerImg
    }
  },
  computed: {
    ...mapGetters(['contentAbout']),
    timeLineData () {
      const reg = /\d+/g
      if (this.contentAbout) {
        this.contentAbout.timeLines.map(item => {
          const matches = item.date.match(reg)
          const year = Number(matches[0])
          let index = -1
          item.month = matches[1]
          for (const i in this.years) {
            if (this.years[i] === year) {
              index = i
              break
            }
          }
          if (index > -1) {
            item.left = this.getValueLeft(index, item.month)
          }
        })
        return this.contentAbout.timeLines
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapActions(['fetchContentByKey']),
    getRecentYears () {
      const now = new Date()
      const year = now.getFullYear()
      return [year - 1, year, year + 1]
    },
    getValueLeft (index, month) {
      const basic = 80
      const range = 460
      const every = 29.5
      let left = basic + range * index
      left += (month - 1) * every
      return left / 10
    },
    handleSwipeChange (index) {
      this.activeIndex = index
    },
    changeSwipe (type) {
      this.$refs['swipe-dev'][type]()
    },
    changeSlide (index) {
      this.$refs['swipe-dev'].swipeTo(index)
    }
  },
  created () {
    this.years = this.getRecentYears()
    this.fetchContentByKey('About')
  }
}
</script>

<style lang="less" scoped>
  .about{
    .compute{
      padding: 50px 50px 40px;
      .text{
        margin: 40px 0;
        color: @grey;
        line-height: 44px;
        font-size: 28px;
        text-align: center;
      }
      .img-box{
        width: 100%;
      }
    }
    .team{
      padding: 40px;
      background: #F5F7FF;
      .list{
        margin-top: 20px;
        .team-item{
          position: relative;
          width: 100%;
          padding: 94px 32px 36px;
          margin-top: 30px;
          background: #fff;
          .intro{
            .flex(@direction: column, @align: flex-end);
            position: absolute;
            left: 0;
            top: -10px;
            width: 262px;
            height: 75px;
            padding-right: 15px;
            background-image: url("~@/assets/images/about/bg_intro.png");
            .full_bg;
            color: #fff;
            h4{
              font-size: 26px;
              margin-bottom: 6px;
            }
            h5{
              font-size: 20px;
              &.small{
                font-size: 18px;
              }
            }
          }
          .info-box{
            padding: 0 35px;
            .title-box{
              color: @blue;
              strong{
                .flex(@justify: flex-start);
                max-width: 100%;
                .textEllipsisSingle;
                font-size: 28px;
                font-weight: 400;
                line-height: 44px;
                &::before{
                  content: "";
                  display: inline-block;
                  width: 4px;
                  height: 4px;
                  background: @blue;
                  margin-right: 15px;
                }
              }
            }
            .description{
              margin-top: 20px;
              color: @grey;
              font-size: 24px;
              line-height: 34px;
            }
          }
        }
      }
    }
    .develop{
      padding: 50px 0;
      overflow: hidden;
      background-image: url("~@/assets/images/about/bg_develop.jpg");
      .full_bg;
      .timeline-box{
        position: relative;
        height: 60px;
        padding: 0 40px;
        margin-top: 62px;
        &::after{
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          z-index: 0;
          margin-top: -1px;
          width: 100%;
          height: 2px;
          background: #4b639c;
        }
        .timeline{
          height: 100%;
          color: #fff;
          .flex(@direction: column);
          position: relative;
          z-index: 1;
          .year{
            width: 100%;
            .flex(space-between);
            li{
              width: 52px;
              height: 52px;
              line-height: 52px;
              background: #4b639c;
              border-radius: 50%;
              text-align: center;
            }
          }
          .event{
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            li{
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              cursor: pointer;
              p{
                position: absolute;
                left: 50%;
                bottom: -40px;
                min-width: 100px;
                font-size: 22px;
                text-align: center;
                transform: translateX(-50%);
              }
              i{
                display: block;
                width: 35px;
                height: 35px;
                background-color: #fff;
                border: 4px rgba(255,255,255,.6) solid;
                background-clip: padding-box;
                border-radius: 50%;
              }
              &.active{
                color: rgb(247,182,61);
                i{
                  background-color: rgb(247,182,61);
                  border-color: rgba(247,182,61,.6);
                }
              }
            }
          }
        }
      }
      .swiper-box{
        color: #fff;
        padding: 0 40px;
        margin-top: 80px;
        .img-box{
          .flex;
          width: 670px;
          height: 230px;
          overflow: hidden;
          img{
            width: 100%;
          }
        }
        .text-box{
          width: 670px;
          margin-top: 40px;
          h4{
            font-size: 28px;
            margin-bottom: 24px;
            max-width: 100%;
            .textEllipsisSingle;
          }
          p{
            line-height: 34px;
            font-size: 24px;
            opacity: .75;
          }
        }
      }
      .swipe-btn-box{
        .flex;
        margin-top: 50px;
        .swipe-button{
          &:first-child{
            margin-right: 25px;
          }
          svg{
            width: 54px;
            height: 54px;
            fill: @blue;
          }
        }
      }
    }
  }
</style>
