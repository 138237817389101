<template>
  <hgroup class="common-title" :class="{center: align === 'center',light: light === 'yes'}">
    <h5>{{subTitle}}</h5>
    <h3 v-html="title"></h3>
  </hgroup>
</template>

<script>
export default {
  name: 'commonTitle',
  props: {
    title: String,
    subTitle: String,
    align: {
      type: String,
      default: 'center'
    },
    light: {
      type: String,
      default: 'no'
    }
  }
}
</script>

<style lang="less" scoped>
.common-title{
  position: relative;
  z-index: 5;
  h5{
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 400;
    color: @black;
    text-transform: uppercase;
  }
  h3{
    font-size: 34px;
    font-weight: bold;
    color: @black;
    padding-bottom: 28px;
    position: relative;
    &::after{
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 30px;
      height: 3px;
      background: @blue;
    }
  }
  &.center{
    width: 100%;
    text-align: center;
    h3{
      &::after{
        left: 50%;
        margin-left: -15px;
      }
    }
  }
  &.light{
    h5{
      color: #fff;
      opacity: .6;
    }
    h3{
      color: #fff;
      &::after{
        background: rgba(255,255,255,.4);
      }
    }
  }
}
</style>
